import React from 'react'
import AboutMiTitle from '../assets/aboutmi-title.png'
import '../styles/Aboutmi.css';


function Aboutmi() {
  return (
    <div className='aboutmi' id='aboutmi'>
        <div className='leftSide'>
            {/* <img alt='' src={AboutMi}/>  */}
        </div>
        <div className='rightSide'>
            <img className='title' alt='' src={AboutMiTitle}/> 
            <p className='titleone'>
                Bienvenida  hermosa alma
            </p>
            <p>
                Mi nombre es Camila y estoy aquí para compartirte todo lo que sé y aprender de ti también.
            </p>
            <p>
                Hace 4 años decidí  dejar todo lo conocido y cambiar mi vida. Renuncié a mi trabajo y me fui a viajar por el mundo, en esta aventura solté todo lo que creía ser y empecé a contactar con lo que realmente soy. 
            </p>
            <p>
                Mi corazón se alineó con el proposito de mi alma, servir desde el amor.
            </p>
            <p>
                Bali, Nepal y Tailandia fueron los lugares donde pude aprender y certificarme como profesora y facilitadora de lo que ahora estoy aquí en Ecuador para ofrecer.
            </p>

        </div>
    </div>
  )
}

export default Aboutmi