import React from 'react'
import Contact from '../assets/contacto.png'
import Instagram from '../assets/instagram.png'
import Facebook from '../assets/facebook.png'
import Tiktok from '../assets/tiktok.png'
import '../styles/Footer.css'

function Footer() {
  return (
    <div className='footer'>
      
      <div className='stars'></div>
      <div className='row'>
        <div className='leftSide'>
          <img className='contact'  alt='' src={Contact}  /> 
          <p>Celular: (+593) 096 238 2597</p>
          <p>Email: camiverbik@gmail.com</p>
        </div>
        <div className='rightSide'>
          <div>
            <a href="https://www.instagram.com/camila.verbik">
            <img className='social' alt='' src={Instagram} /> 
            </a>
            <a href="https://www.facebook.com/milu.verbik">
            <img className='social' alt='' src={Facebook} /> 
            </a>
            <a href="https://www.tiktok.com/@camilaverbik">
            <img className='social' alt='' src={Tiktok} /> 
            </a>
          </div>
        </div>
      </div>
      
    </div>
  )
}

export default Footer