// rfce
import React from 'react';
import HeaderText from '../assets/headerText.png'
import '../styles/Header.css';
import { Button } from '@mui/material'
import { HashLink } from 'react-router-hash-link';

function Header() {
  return (
    <div className='header'>
        <div className='leftSide'></div>
        <div className='rightSide'>
            <img alt='' src={HeaderText}/>    
            <p>
                Regresa a tu centro a través de prácticas, herramientas & talleres que elevarán tu energía y te acercarán al amor que en ti habita.
            </p>
            <Button component={HashLink} to="#aboutmi" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>SOBRE MI</Button>
            
        </div>
    </div>
  )
}

export default Header