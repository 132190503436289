import React from 'react'
import TestimonialsTitle from '../assets/testimonials.png'
import Quotation from '../assets/comillas.png'
import '../styles/Testimonials.css';

function Testimonials() {
  return (
    <div className='testimonials'>
        <img className='title' alt='' src={TestimonialsTitle} /> 
        <div className='stars'></div>
        
        <div className='row'>
            <div className='card'>
            <img className='quotation' alt='' src={Quotation} /> 
              <p>
                La lectura de cartas fue un destello de luz que iluminó mi camino y tocó mi corazón de una manera hermosa. Gracias por tu tiempo, tus palabras llenas de sabiduría y por guiarme en este momento especial. Tu don y tu energía son verdaderamente inspiradores.
              </p>
              <h2>
                -ALEXANDRA T. 
              </h2>
            </div>
            <div className='card'>
            <img className='quotation' alt='' src={Quotation} /> 
              <p>
                Cami, quería agradecerte con todo mi corazón por haber sostenido la ceremonia de cumpleaños para mis 30, todos amamos, nos expandimos y nos acercamos más a nuestro centro gracias a tu dirección, energía y entrega que se nota que es con tanto amor y compromiso
              </p>
              <h2>
                -LINA D. 
              </h2>
            </div>
            <div className='card'>
            <img className='quotation' alt='' src={Quotation} /> 
              <p>
                Cami te cuento que gracias a healy y a tu espíritu que brilla hoy siento que mis ataques de pánico disminuyeron y no solo eso, mi energía se transformó totalmente, gracias por viajar a Colombia para colocarme las microcorrientes fue lo mejor.
              </p>
              <h2>
                -FELIPE C.
              </h2>
            </div>
        </div>
    </div>
  )
}

export default Testimonials