import './App.css';
import Navbar from './components/Navbar';
import Home from './pages/Home';
import Header from './components/Header';
import Footer from './components/Footer';
import {BrowserRouter as Router} from 'react-router-dom';
import Moreabout from './components/Moreabout';
import Aboutmi from './components/Aboutmi';
import Services from './components/Services';
import Testimonials from './components/Testimonials';
import Values from './components/Values';

function App() {
  return (
    <div className="App">
      <Router>
        {/* <Navbar/> */}
        <Header/>
        <Moreabout/>
        <Aboutmi/>
        <Services/>
        <Values/>
        <Testimonials/>
        {/* <Routes> */}
          {/* <Route path="/" element={<Home />} /> */}
          {/* <Route path="*" element={<NotFound />} /> */}
        {/* </Routes> */}
        <Footer/>
      </Router>
    </div>
  );
}

export default App;
