import React from 'react';
import Cards from '../assets/cartas.jpg';
import Group from '../assets/grupo.jpg';
import Healy from '../assets/healy.jpg';
import Hands from '../assets/manos.jpg';
import Tarot from '../assets/tarot.jpg';
import Soundbath from '../assets/soundbath.jpg';
import ServicesTitle from '../assets/services.png'
import '../styles/Services.css';


function Services() {
  return (
    <div className='services'>
        
        <img className='title' alt='' src={ServicesTitle} /> 
        <div className='row3'>
            <div className='card'>
                <img alt='' src={Cards}/> 
                <h2 className='h22'>Ceremonias</h2>
                <p>Es la forma perfecta de celebrar y conmemorar un momento especial de tu vida. Puede ser desde un cumpleaños y baby shower, hasta la partida a otro país  tuya o de una persona que amas.
                    Es un espacio sostenido y guiado, con prácticas hermosas que te permitirán conectar contigo y con las personas ahí presentes. Rodeando un altar de flores y cristales, recibirás una meditación, sound bath, cacao entre otras cosas. 
                </p>
            </div>
            <div className='card'>
                <img alt='' src={Group}/> 
                <h2 className='h22'>Talleres autoconocimiento</h2>
                <p>Talleres con alma, enfocados a  temas como autocompasión, gratitud, amor propio, energía femenina, trabajo de sombra, entre otros, que te permitirán navegar, conectar, profundizar para 
                    así hacer el subconsciente, consciente y poder trabajar desde ahí, logrando poco a poco sanar tu cuerpo, mente y espíritu. Aprenderás herramientas poderosas para conectar contigo misma en tu día a día
                </p>
            </div>
            <div className='card'>
                <img alt='' src={Healy}/> 
                <h2 className='h22'>Healy</h2>
                <p>Terapia a través de un dispositivo de alta tecnología desarrollado para el bienestar integral y la vitalidad. Mientras marcas tu intención, Healy se encargará de leer tus 5 cuerpos, físico, mental, emocional, espiritual y etérico, logrando así determinar qué cuerpos necesitan balance y te enviará las frecuencias necesarias con el fin de equilibrar y sanarlos.
                </p>
            </div>
        </div>
        
        <div className='row3'>
            <div className='card'>
                <img alt='' src={Soundbath}/> 
                <h2>Sound Bath & Cristales</h2>
                <p>En esta terapia empezaremos por conectar a través de una meditación y movimientos sutiles del cuerpo para luego entregarnos   a esta experiencia de sonido donde distintas frecuencias vibratorias harán que tu cuerpo físico, mental, emocional y energético se relajen y logres así entrar en un estado de meditación logrando recalibrar tu cuerpo </p>
            </div>
            <div className='card'>
                <img alt='' src={Hands}/> 
                <h2>Barras de Access </h2>
                <p>Existen 32 puntos específicos en tu cabeza que, al ser tocados gentilmente, liberan sin esfuerzo creencias pensamientos, ideas y emociones que nos limitan, bloquean e impiden crear la vida que deseamos. Al liberar estos bloqueos, podremos mejorar la salud física, emocional, mental y así lograr un mayor sentido de paz y claridad.</p>
            </div>
            <div className='card'>
                <img alt='' src={Tarot}/> 
                <h2>Tarot & Oráculo</h2>
                <p>Perfecto para dar claridad a tus dudas. Tarot terapéutico nos va a mostrar la energía que está disponible para ti  en este momento en los temas que desees saber.
                </p>
                <p>Y el Oráculo viene acompañado de la canalización de mensajes que Ángeles, Arcángeles y Guías tienen para ti. Trabajamos con seres de alta frecuencia para que todas las respuestas que recibas, provengan desde la luz y el amor.
                </p>
            </div>
        </div>
        <div className='row2'>
            <div className='card'>
                <img alt='' src={Cards}/> 
                <h2>Ceremonias</h2>
                <p>Es la forma perfecta de celebrar y conmemorar un momento especial de tu vida. Puede ser desde un cumpleaños y baby shower, hasta la partida a otro país  tuya o de una persona que amas.
                    Es un espacio sostenido y guiado, con prácticas hermosas que te permitirán conectar contigo y con las personas ahí presentes. Rodeando un altar de flores y cristales, recibirás una meditación, sound bath, cacao entre otras cosas. 
                </p>
            </div>
            <div className='card'>
                <img alt='' src={Group}/> 
                <h2>Talleres autoconocimiento</h2>
                <p>Talleres con alma, enfocados a  temas como autocompasión, gratitud, amor propio, energía femenina, trabajo de sombra, entre otros, que te permitirán navegar, conectar, profundizar para 
                    así hacer el subconsciente, consciente y poder trabajar desde ahí, logrando poco a poco sanar tu cuerpo, mente y espíritu. Aprenderás herramientas poderosas para conectar contigo misma en tu día a día
                </p>
            </div>
            
        </div>
        <div className='row2'>
            <div className='card'>
                <img alt='' src={Healy}/> 
                <h2>Healy</h2>
                <p>Terapia a través de un dispositivo de alta tecnología desarrollado para el bienestar integral y la vitalidad. Mientras marcas tu intención, Healy se encargará de leer tus 5 cuerpos, físico, mental, emocional, espiritual y etérico, logrando así determinar qué cuerpos necesitan balance y te enviará las frecuencias necesarias con el fin de equilibrar y sanarlos.
                </p>
            </div>
            <div className='card'>
                <img alt='' src={Soundbath}/> 
                <h2>Sound Bath & Cristales</h2>
                <p>En esta terapia empezaremos por conectar a través de una meditación y movimientos sutiles del cuerpo para luego entregarnos   a esta experiencia de sonido donde distintas frecuencias vibratorias harán que tu cuerpo físico, mental, emocional y energético se relajen y logres así entrar en un estado de meditación logrando recalibrar tu cuerpo </p>
            </div>
            
        </div>
        <div className='row2'>
            <div className='card'>
                <img alt='' src={Hands}/> 
                <h2>Barras de Access </h2>
                <p>Existen 32 puntos específicos en tu cabeza que, al ser tocados gentilmente, liberan sin esfuerzo creencias pensamientos, ideas y emociones que nos limitan, bloquean e impiden crear la vida que deseamos. Al liberar estos bloqueos, podremos mejorar la salud física, emocional, mental y así lograr un mayor sentido de paz y claridad.</p>
            </div>
            <div className='card'>
                <img alt='' src={Tarot}/> 
                <h2>Tarot & Oráculo</h2>
                <p>Perfecto para dar claridad a tus dudas. Tarot terapéutico nos va a mostrar la energía que está disponible para ti  en este momento en los temas que desees saber.
                </p>
                <p>Y el Oráculo viene acompañado de la canalización de mensajes que Ángeles, Arcángeles y Guías tienen para ti. Trabajamos con seres de alta frecuencia para que todas las respuestas que recibas, provengan desde la luz y el amor.
                </p>
            </div>
        </div>
        

    </div>
  )
}

export default Services