import { Button } from '@mui/material'
import React from 'react'
import { HashLink } from 'react-router-hash-link';
import QuestionTitle from '../assets/question.png'
import '../styles/Moreabout.css';

function Moreabout() {
  return (
    <div className='moreabout'>
        <div className='leftSide'>
            <img className='title' alt='' src={QuestionTitle}  /> 
            <p>
                Estoy aquí para acompañarte a recordar tu magia, poder y luz a través de hermosas y profundas prácticas y terapias que te ayudarán a reconectar con el amor que llevas dentro.
            </p>
            <p>
                Encontrarás en ti todo lo que necesitas, solo debemos ir un poco más profundo, para escuchar lo que tu alma y corazón tienen por decir. Esto lo haremos a través de distintas herramientas, tu podrás elegir las que más resuenen contigo.
            </p>
            <p>
                Te guiaré y acompañaré en cada paso del proceso hacia la reconexión contigo mismo.
            </p>
            <Button component={HashLink} to="#aboutmi" scroll={(el) => el.scrollIntoView({ behavior: 'smooth', block: 'start' })}>CONOCE MÁS</Button>
        </div>
        <div className='rightSide'>

        </div>
    </div>
  )
}

export default Moreabout