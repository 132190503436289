import React from 'react'
import { Button } from '@mui/material'
import ValuesTitle from '../assets/values.png'
import { HashLink } from 'react-router-hash-link';
import '../styles/Values.css'

function Values() {
  return (
    <div className='values'>  
      <img className='title' alt='' src={ValuesTitle} width="800" /> 
      <h3>*Todos los valores aquí mencionados son por persona</h3>
        <div className='row'>
            <div className='card'>
              <h2>CEREMONIAS</h2>
              <h4 className='hcuatro'>De 5- 10 personas</h4>
              <h3>Cumpleaños -Unión de pareja</h3>
              <h3>Despedida - Baby Shower</h3>
              
              <div className='product'>
                <p className='circle'>$133</p>
              </div>
            </div>
            <div className='card'>
              <h2>HEALY </h2>
              <div className='product'>
                <p className='circle'>$77</p>
                <div>
                  <h3>Primera Sesión  </h3>
                  <h4 > *2 -3 horas en persona</h4>
                </div>
                
              </div>
              <div className='product'>
                <h3 className='hh'>Vibración a distancia 1 hora x 30 días</h3>
                <p className='circle'>$222</p>
              </div>
              <div className='product'>
                <p className='circle'>$44</p>
                <div>
                  <h3 className='hh'>Micro-corriente Terapia 1 hora  </h3>
                  <h4 > *Presencial</h4>
                </div>
                
              </div>
              
            </div>
        </div>
        <div className='rowLeft'>
            
            <div className='card'>
              <h2>SOUND BATH & CRISTALES</h2>
              <div className='product'>
                
                <div>
                  <h3>Terapia Personalizada</h3>
                  <h4>2-5 personas</h4>
                </div>
                <p className='circle'>$44</p>
              </div>
              <div className='product'>
                <p className='circle'>$33</p>
                <div>
                  <h3>Terapia Grupal</h3>
                  <h4>6 o más personas</h4>
                </div>
                
              </div>
            </div>
            <div className='card'>
              <h2>TALLERES</h2>
              <h3>Incluye materiales, altar</h3>
              <h3>de flores y cristales, snacks</h3>
              
              <div className='product'>
                <p className='circle'>$25</p>
              </div>
              <h4 className='hcuatro'>*mínimo 5 personas</h4>
            </div>
            
        </div>
        <div className='row'>
            
            <div className='card'>
              <h2>TAROT & ORÁCULO</h2>
              <h3>Sesión de 60 minutos</h3>
              <div className='product'>
                <p className='circle'>$33</p>
                <h3>Sesión de 3 preguntas</h3>
                
              </div>
              <h4 className='hcuatro'>*Via Zoom o presencial</h4>
              
             
            </div>
            <div className='card'>
            <h2>BARRAS DE ACCESS</h2>
            <div className='product'>
                <p className='circle'>$55</p>
                
                <div>
                  <h3>Primera Sesión</h3>
                  <h4> *Terapia 1  hora en persona</h4>
                </div>
              </div>
              <div className='product'>
                
                <div>
                  <h3>Paquete 4 Sesiones</h3>
                  <h4> *Presencial</h4>
                </div>
                <p className='circle'>$155</p>
              </div>
            </div>
            
        </div>
        <Button component={HashLink} to="https://wa.link/u1y2lc">QUIERO RECONECTAR CON MI PODER</Button>
        
    </div>
  )
}

export default Values